import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  IResourceActionInner,
  IResourceMethodObservable,
  IResourceResponse,
  Resource,
  ResourceAction,
  ResourceGlobalConfig,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod,
  ResourceResponseBodyType,
} from '@ngx-resource/core';
import { AppState } from '@shared/state/interfaces/app-state.interface';
import { environment } from 'src/environments/environment';

ResourceGlobalConfig.url = environment.urlApi;

@Injectable({ providedIn: 'root' })
@ResourceParams({
  pathPrefix: '',
})
export class BaseResource extends Resource {
  pagination = null;
  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/getOptions',
    pathPrefix: '',
  })
  getOptions: IResourceMethodObservable<any, any> | any;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    isArray: false,
    path: '/',
    responseBodyType: ResourceResponseBodyType.Json,
  })
  query: IResourceMethodObservable<any, any> | any;

  @ResourceAction({
    path: '/customReport',
    pathPrefix: '',
    responseBodyType: ResourceResponseBodyType.Blob,
  })
  report: IResourceMethodObservable<any, any> | any;

  @ResourceAction({
    path: '/{!id}',
  })
  get: IResourceMethodObservable<any, object> | any;

  @ResourceAction({
    path: '/',
    method: ResourceRequestMethod.Post,
  })
  store: IResourceMethodObservable<object, object> | any;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!id}',
  })
  update: IResourceMethodObservable<object, object> | any;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!id}/restore',
  })
  restore: IResourceMethodObservable<object, object> | any;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!id}',
  })
  delete: IResourceMethodObservable<object, object> | any;

  /**
   *
   * resource.selec({
   *  User: { _sort: '-created_at', _q: 'jhon' }
   * })
   */
  @ResourceAction({
    method: ResourceRequestMethod.Post,
    pathPrefix: '',
    path: '/selects',
  })
  selects: IResourceMethodObservable<any, any>;

  // eslint-disable-next-line ngrx/use-consistent-global-store-name
  constructor(requestHandler?: ResourceHandler, private _router?: Router, private _store?: Store<{ app: AppState }>) {
    super(requestHandler);
  }

  override $handleSuccessResponse(options: IResourceActionInner, resp: IResourceResponse): any {
    if (!environment.production) {
      console.debug(resp);
    }
    /*   this._store.dispatch(
      updateAppState({
        app: { isLoading: false },
      })
    ); */
    let body = resp.body;
    if (Array.isArray(body)) {
      body = this.$prepareSuccessResponseBodyArray(body, options);
    } else {
      body = this.$prepareSuccessResponseBodyObject(body, options);
    }
    if (this.$_canSetInternalData(options)) {
      options.returnData.$resolved = true;
    }
    if (options.actionOptions && options.actionOptions.asResourceResponse) {
      resp.body = body;
      body = resp;
    }
    if (options.actionAttributes && options.actionAttributes.onSuccess) {
      options.actionAttributes.onSuccess(body);
    }
    return body;
  }

  override $getHeaders(actionOptions: any) {
    const headers = super.$getHeaders() || actionOptions.headers || ResourceGlobalConfig.headers || {};
    /*  this._store.dispatch(
      updateAppState({
        app: { isLoading: true },
      })
    ); */

    const token = window.localStorage.getItem('token');
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return headers;
  }

  override $handleErrorResponse(options: IResourceActionInner, resp: IResourceResponse<any>) {
    /*  this._store.dispatch(
      updateAppState({
        app: { isLoading: false },
      })
    ); */
    if (resp?.status === 403) {
      /*  this._toastr.warning('No tiene Permisos para poder realizar esta acción', 'Permisos' || 'Precaución', {
        timeOut: 4000,
      }); */
      /* this._toastService.warning({
        title: 'Permisos',
        message: 'No tiene Permisos para poder realizar esta acción',
      }); */
    }
    if (resp?.status === 401) {
      //window.localStorage.clear();
      //window.sessionStorage.clear();
      this._router.navigateByUrl('/auth/login');
    }
    if (options.returnData && this.$_canSetInternalData(options)) {
      options.returnData.$resolved = true;
    }
    if (options.actionAttributes && options.actionAttributes.onError) {
      options.actionAttributes.onError(resp);
    }
    throw resp;
  }
}
