import { ProtocolState } from '@modules/protocol/interfaces/protocol';
import { createReducer, on } from '@ngrx/store';
import { setProtocolState, updateProtocolState } from '../actions/protocol.actions';

export const initialState = {};

const _protocolReducer = createReducer(
  initialState,
  on(setProtocolState, (state, { protocol }): ProtocolState => ({ ...state, ...protocol })),
  on(updateProtocolState, (state, { protocol }): ProtocolState => ({ ...state, ...protocol }))
);

export const protocolReducer = (state: any, action: any) => _protocolReducer(state, action);
