import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../interfaces/app-state.interface';

export const featureKey = 'app';

export type FeatureState = AppState;
// selectFeature will have the type MemoizedSelector<object, FeatureState>
export const selectAppFeature = createFeatureSelector<FeatureState>(featureKey);

// selectFeatureCount will have the type MemoizedSelector<object, number>
export const selectApp = createSelector(selectAppFeature, state => state);
export const selectAppIsLoading = createSelector(selectAppFeature, state => state?.isLoading);
export const selectAppNotificationNew = createSelector(selectAppFeature, state => state?.notificationNew);
