import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../app/containers/base/base.module').then(m => m.BaseModule),
  },
  {
    path: '',
    loadChildren: () => import('../app/containers/unauth/unauth.module').then(m => m.UnauthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
