import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localES from '@angular/common/locales/es-MX';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { UntypedFormBuilder } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { environment } from '@environments/environment';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AuthResource } from '@modules/auth/resources/auth.resource';
import { protocolReducer } from '@modules/protocol/state/reducers/protocol.reducer';
import { UserResource } from '@modules/user/resources/user.resource';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ResourceModule } from '@ngx-resource/handler-ngx-http';
import { CanActivateViaAuthGuard } from '@shared/guards/CanActivateViaAuthGuard';
import { PipesModule } from '@shared/pipes/pipes.module';
import { BaseResource } from '@shared/resources/base.resources';
import { BaseCrudService } from '@shared/services/base-crud.service';
import { BaseListService } from '@shared/services/base-list.service';
import { GlobalService } from '@shared/services/global.service';
import { ToastService } from '@shared/services/toast.service';
import { appReducer } from '@shared/state/reducers/app.reducer';
import { sessionReducer } from '@shared/state/reducers/session.reducer';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { LaddaModule } from 'angular2-ladda';
import { AutofocusFixModule } from 'ngx-autofocus-fix';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CloseTooltipService } from '@shared/services/close-tooltip.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};
registerLocaleData(localES, 'es');

export const apiConfigProvider = (global: GlobalService) => () => global.init();

@NgModule({
  declarations: [AppComponent],
  imports: [
    FilterPipeModule,
    PipesModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    TypeaheadModule.forRoot(),
    AppRoutingModule,
    AutocompleteLibModule,
    LaddaModule,
    HttpClientModule,
    AutofocusFixModule.forRoot({
      async: true,
      smartEmptyCheck: true,
      triggerDetectChanges: true,
    }),
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot({
      animation: 'pulse',
      loadingText: 'Cargando...',
    }),
    PerfectScrollbarModule,
    ResourceModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
    provideRemoteConfig(() => getRemoteConfig()),
    StoreModule.forRoot({}, {}),
    StoreModule.forRoot({ session: sessionReducer, app: appReducer, protocol: protocolReducer }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
          autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        })
      : [],
  ],
  exports: [TypeaheadModule],
  providers: [
    BaseResource,
    ToastService,
    BaseListService,
    AuthResource,
    UserResource,
    BaseCrudService,
    CanActivateViaAuthGuard,
    UntypedFormBuilder,
    CloseTooltipService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN' },
    {
      provide: APP_INITIALIZER,
      useFactory: (storageService: GlobalService) => () => storageService.init(),
      deps: [GlobalService],
      multi: true,
    },
    /*    {
          provide: APP_INITIALIZER,
          useFactory: apiConfigProvider,
          deps: [GlobalService],
          multi: true,
        }, */
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
