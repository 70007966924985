import { Injectable } from '@angular/core';
import { IResourceMethodObservable, ResourceAction, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core';
import { BaseResource } from 'src/app/shared/resources/base.resources';

@Injectable()
@ResourceParams({
  pathPrefix: 'user',
})
export class UserResource extends BaseResource {
  @ResourceAction({
    method: ResourceRequestMethod.Patch,
    path: '/setTokenSender',
  })
  setTokenSender: IResourceMethodObservable<any, any> | any;
}
