import { AfterViewInit, ChangeDetectorRef, Directive, Inject, Injector } from '@angular/core';
import { ModalOptions } from 'ngx-bootstrap/modal';

@Directive()
export class OpenNewServiceService implements AfterViewInit {
  protected _modalService: any;
  selects: any = {};
  protected _cdr: ChangeDetectorRef;
  entity: any = {};

  constructor(@Inject(Injector) protected injector: Injector) {}

  async openDialogAddSector() {
    const { SectorCreateComponent } = await import(
      '../../modules/sector/components/sector-create/sector-create.component'
    );
    this.openDialogAdd(SectorCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Sector = [...this.selects.Sector, entity];
          this.entity.sector = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddCompany() {
    const { CompanyCreateComponent } = await import(
      '../../modules/company/components/company-create/company-create.component'
    );
    this.openDialogAdd(CompanyCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Company = [...this.selects.Company, entity];
          this.entity.company = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddCourse() {
    const { CourseCreateComponent } = await import(
      '../../modules/course/components/course-create/course-create.component'
    );
    this.openDialogAdd(CourseCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Course = [...this.selects.Course, entity];
          this.entity.course = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddProvider() {
    const { ProvidersCreateComponent } = await import(
      '../../modules/providers/components/providers-create/providers-create.component'
    );
    this.openDialogAdd(ProvidersCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.CompanyProvider = [...this.selects.CompanyProvider, entity];
          this.entity.company_provider = {
            ...entity,
            id: entity?.entity?.id,
          };
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddPosition() {
    const { PositionCreateComponent } = await import(
      '../../modules/position/components/position-create/position-create.component'
    );
    this.openDialogAdd(PositionCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Position = [...this.selects.Position, entity];
          this.entity.position = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddState() {
    const { StateCreateComponent } = await import('../../modules/state/components/state-create/state-create.component');
    this.openDialogAdd(StateCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.State = [...this.selects.State, entity];
          this.entity.state = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddWorkSchedule() {
    const { WorkScheduleCreateComponent } = await import(
      '../../modules/work-schedule/components/work-schedule-create/work-schedule-create.component'
    );
    this.openDialogAdd(WorkScheduleCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.WorkSchedule = [...this.selects.WorkSchedule, entity];
          this.entity.work_schedule = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddWarehouse() {
    const { WarehouseCreateComponent } = await import(
      '../../modules/warehouse/components/warehouse-create/warehouse-create.component'
    );
    this.openDialogAdd(WarehouseCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Warehouse = [...this.selects.Warehouse, entity];
          this.entity.warehouse = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddEmployeePeriod(state = {}) {
    const { EmployeePeriodCreateComponent } = await import(
      '../../modules/employee/components/period/employee-period-create/employee-period-create.component'
    );
    this.openDialogAdd(
      EmployeePeriodCreateComponent,
      (entity: any) => {
        if (entity) {
          if (entity && entity?.id) {
            this.selects.EmployeePeriod = [...this.selects.EmployeePeriod, entity];
            this.entity.state = entity;
            this._cdr.detectChanges();
          }
        }
      },
      state
    );
  }

  async openDialogAddPatient(state = {}) {
    const { PatientCreateComponent } = await import(
      '../../modules/patient/components/patient-create/patient-create.component'
    );
    this.openDialogAdd(PatientCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Patient = [...this.selects.Patient, entity];
          this.entity.patient = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddSpeciality() {
    const { SpecialityCreateComponent } = await import(
      '../../modules/speciality/components/speciality-create/speciality-create.component'
    );
    this.openDialogAdd(SpecialityCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Speciality = [...this.selects.Speciality, entity];
          this.entity.speciality = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddBrand() {
    const { BrandCreateComponent } = await import('../../modules/brand/components/brand-create/brand-create.component');
    this.openDialogAdd(BrandCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Brand = [...this.selects.Brand, entity];
          this.entity.brand = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddUnitOfMeasurement() {
    const { UnitOfMeasurementCreateComponent } = await import(
      '../../modules/unit-of-measurement/components/unit-of-measurement-create/unit-of-measurement-create.component'
    );
    this.openDialogAdd(UnitOfMeasurementCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.UnitOfMeasurement = [...this.selects.UnitOfMeasurement, entity];
          this.entity.unit_of_measurement = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddItem() {
    const { MedicineAndMaterialCreateComponent } = await import(
      '../../modules/medicine-and-material/components/medicine-and-material-create/medicine-and-material-create.component'
    );
    this.openDialogAdd(MedicineAndMaterialCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          if (this.selects.Item) {
            this.selects.Item = [...this.selects.Item, entity];
          }
          this.entity.item = entity;
          this.entity.code = entity?.code;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddConcept() {
    const { ConceptCreateComponent } = await import(
      '../../modules/concept/components/concept-create/concept-create.component'
    );
    this.openDialogAdd(ConceptCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Concept = [...this.selects.Concept, entity];
          this.entity.concept = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddBusinessUnit() {
    const { BusinessUnitCreateComponent } = await import(
      '../../modules/business-unit/components/business-unit-create/business-unit-create.component'
    );
    this.openDialogAdd(BusinessUnitCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.BusinessUnit = [...this.selects.BusinessUnit, entity];
          this.entity.business_unit = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddCurrecny() {
    const { CurrencyCreateComponent } = await import(
      '../../modules/currency/components/currency-create/currency-create.component'
    );
    this.openDialogAdd(CurrencyCreateComponent, (entity: any) => {
      if (entity) {
        //se agrega al tipo de cliente aguardado a la lista de types para su seleccion
        if (entity && entity?.id) {
          //se agrega para su seleccion
          this.selects.Currency = [...this.selects.Currency, entity];
          this.entity.currency = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddArea() {
    const { AreaCreateComponent } = await import('../../modules/area/components/area-create/area-create.component');
    this.openDialogAdd(AreaCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Area = [...this.selects.Area, entity];
          this.entity.area = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddTask(callback: any = null) {
    const { TaskCreateComponent } = await import('../../modules/task/components/task-create/task-create.component');
    this.openDialogAdd(TaskCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Task = [...this.selects.Task, entity];
          this.entity.area = entity;
          this._cdr.detectChanges();
        }
        if (callback) {
          callback(entity);
        }
      }
    });
  }

  async openDialogAddCommitteeBiosecurity() {
    const { CommitteeBiosecurityCreateComponent } = await import(
      '../../modules/committee-biosecurity/components/committee-biosecurity-create/committee-biosecurity-create.component'
    );
    this.openDialogAdd(CommitteeBiosecurityCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.CommitteeBiosecurity = [...this.selects.CommitteeBiosecurity, entity];
          this.entity.committeeBiosecurity = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddCommitteeEthic() {
    const { CommitteeEthicCreateComponent } = await import(
      '../../modules/committee-ethic/components/committee-ethic-create/committee-ethic-create.component'
    );
    this.openDialogAdd(CommitteeEthicCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.CommitteeEthic = [...this.selects.CommitteeEthic, entity];
          this.entity.committeeEthic = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddCommitteeInvestigation() {
    const { CommitteeInvestigationCreateComponent } = await import(
      '../../modules/committee-investigation/components/committee-investigation-create/committee-investigation-create.component'
    );
    this.openDialogAdd(CommitteeInvestigationCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.CommitteeInvestigation = [...this.selects.CommitteeInvestigation, entity];
          this.entity.committeeInvestigation = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddTherapeuticArea() {
    const { TherapeuticAreaCreateComponent } = await import(
      '../../modules/therapeutic-area/components/therapeutic-area-create/therapeutic-area-create.component'
    );
    this.openDialogAdd(TherapeuticAreaCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.TherapeuticArea = [...this.selects.TherapeuticArea, entity];
          this.entity.therapeuticArea = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddDoctor() {
    const { DoctorCreateComponent } = await import(
      '../../modules/doctor/components/doctor-create/doctor-create.component'
    );
    this.openDialogAdd(DoctorCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Doctor = [...this.selects.Doctor, entity];
          this.entity.doctor = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddRolDoctor() {
    const { RolDoctorProtocolCreateComponent } = await import(
      '../../modules/rol-doctor-protocol/components/rol-doctor-protocol-create/rol-doctor-protocol-create.component'
    );
    this.openDialogAdd(RolDoctorProtocolCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.RolDoctorProtocol = [...this.selects.RolDoctorProtocol, entity];
          this.entity.rolDoctorProtocol = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddVisit() {
    const { VisitCreateComponent } = await import('../../modules/visit/components/visit-create/visit-create.component');
    this.openDialogAdd(VisitCreateComponent, (entity: any) => {
      if (entity) {
        if (entity && entity?.id) {
          this.selects.Visit = [...this.selects.Visit, entity];
          this.entity.visit = entity;
          this._cdr.detectChanges();
        }
      }
    });
  }

  async openDialogAddContact(state = null, company = null) {
    if (state?.entity) {
      state.entity.contact_type_id = '31dd5cb5-ae78-44f6-9e57-d255463438a4';
      state.entity.company_id = company.company_id;
    }
    const { ContactCreateComponent } = await import(
      '../../modules/contact/components/contact-create/contact-create.component'
    );
    this.openDialogAdd(
      ContactCreateComponent,
      (entity: any) => {
        if (entity) {
          entity = entity?.entity;
          if (entity && entity?.id) {
            entity = {
              ...entity,
              ...entity?.contact,
            };
            this.selects.Contact = [...this.selects.Contact, entity];
            if (company) {
              company?.contact.push(entity);
            } else {
              this.entity.contact = entity;
            }
            this._cdr.detectChanges();
          }
        }
      },
      state
    );
  }

  openDialogAdd(dialogComponent: any, callback: any = null, state = {}) {
    const modalId = Math.random() * 100000000000000000;
    state = {
      isSelectable: true,
      isModal: true,
      ...state,
      modalId,
    };
    const initialState: ModalOptions = {
      id: modalId,
      initialState: state,
      class: 'modal-lg',
      ignoreBackdropClick: true,
      keyboard: false,
    };

    const bsModalRef = this._modalService.show(dialogComponent, initialState);
    bsModalRef?.onHidden.subscribe((result: any) => {
      const entity = bsModalRef?.content?.entity;
      if (callback) {
        callback(entity);
      }
    });
  }

  onSelectItem(item: any, key: string) {}

  ngAfterViewInit() {
    this._cdr.detectChanges();
  }
}
