import { createReducer, on } from '@ngrx/store';
import { setAppState, updateAppState } from '@shared/state/actions/app.actions';

export const initialState = {
  lang: 'es',
  isLoading: false,
  notificationNew: false,
};

const _appReducer = createReducer(
  initialState,
  on(setAppState, (state, { app }) => ({ ...state, ...app })),
  on(updateAppState, (state, { app }) => ({ ...state, ...app }))
);

export const appReducer = (state: any, action: any) => _appReducer(state, action);
