import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CloseTooltipService {
  private tooltipsSubject = new Subject<void>();

  tooltips$ = this.tooltipsSubject.asObservable();

  constructor() {}

  registerTooltip(tooltip: HTMLElement) {}

  closeTooltips() {
    this.tooltipsSubject.next();
  }
}
