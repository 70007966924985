import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToastService {
  constructor(private _toastr: ToastrService) {}

  error(data: any) {
    const toast = this._toastr.error(data.message, data.title || 'Error', {
      timeOut: data.timeOut || 4000,
    });
  }

  success(data: any) {
    const toast = this._toastr.success(data.message, data.title || 'Éxito', {
      timeOut: data.timeOut || 4000,
    });
  }

  warning(data: any) {
    const toast = this._toastr.warning(data.message, data.title || 'Precaución', {
      timeOut: data.timeOut || 4000,
    });
  }

  info(data: any) {
    const toast = this._toastr.info(data.message, data.title || 'Información', {
      timeOut: data.timeOut || 4000,
    });
  }
}
