import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { updateAppState } from '@shared/state/actions/app.actions';
import { isArray as _isArray, omitBy as _omitBy } from 'lodash-es';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, of, Subject, tap } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ToastService } from './toast.service';
import { ISelectType } from '@shared/global.interface';
import * as moment from 'moment/moment';

interface Ipagination {
  _limit?: number;
  _page?: number;
  _sort?: string;
}

@Directive()
export class BaseListService<T = Record<any, any>> implements OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() load = new EventEmitter<any>();
  @Input() isSelectable = false;
  @Input() edit = true;
  @Input() create = true;
  @Input() report = true;
  @Input() remove = true;
  @Input() preserveQueryParams = true;
  isModal = false;
  protected _entity: BehaviorSubject<T | null> = new BehaviorSubject(null);
  protected _notFound: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected _entities: BehaviorSubject<T[] | null> = new BehaviorSubject(null);
  public _isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public router: Router;
  public route;
  private _pagination: BehaviorSubject<any | null> = new BehaviorSubject<Ipagination>({
    _limit: 15,
    _page: 1,
    _sort: '-increment',
  });

  //modal
  dialogNew: any;
  dialogEdit: any;
  dialogDetail: any;
  bsModalRef: BsModalRef;
  @Input() selects: ISelectType = {} as ISelectType;

  protected _cdr: ChangeDetectorRef;

  protected _unsubscribeAll: Subject<any> = new Subject<any>();

  protected _resource: any;

  toastService;
  _modalService;

  @Input() public wheres: Record<string, any> = {
    _active: true,
  };

  /**
   * Getter for product
   */
  get entity$(): Observable<T> {
    return this._entity.asObservable();
  }

  /**
   * Getter for product
   */
  get entities$(): Observable<T[]> {
    return this._entities.asObservable();
  }

  /**
   * Getter for product
   */
  get isLoading$(): Observable<boolean> {
    return this._isLoading.asObservable();
  }

  /**
   * Pagination
   */
  get pagination$(): Observable<any> {
    return this._pagination.asObservable();
  }

  /**
   * Pagination
   */
  get notFound$(): Observable<boolean> {
    return this._notFound.asObservable();
  }

  protected _store: Store;

  constructor(@Inject(Injector) private injector: Injector) {
    this.toastService = injector.get(ToastService);
    this._cdr = injector.get(ChangeDetectorRef);
    this.bsModalRef = injector.get(BsModalRef);
    this._modalService = injector.get(BsModalService);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this._store = injector.get(Store);
    //recovey query params for reuquest on state search bofore
    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe((queryParams: any) => {
      if (!this.preserveQueryParams) {
        return;
      }
      if (!queryParams) {
        return;
      }
      let wheres = queryParams;
      const wheresParse: Record<string, any> = {};
      if (this.wheres.hasOwnProperty('_active') && queryParams.hasOwnProperty('_active')) {
        if (queryParams._active === 'true') {
          wheresParse._active = true;
        } else if (queryParams._active === 'false') {
          wheresParse._active = false;
        } else {
          wheresParse._active = null;
        }
      }

      wheres = {
        ...wheres,
        ...wheresParse,
      };

      const { _page, _limit, ...other } = wheres;
      this.wheres = {
        ...this.wheres,
        ...other,
      };
      if (queryParams?._page) {
        this.wheres._page = queryParams?._page;
      }
      if (queryParams?._limit) {
        this.wheres._limit = queryParams?._limit;
      }
      if (this._cdr) {
        this._cdr.markForCheck();
      }
    });
  }

  ngOnInit(): void {
    this.wheres._active = true;
    this.getList().subscribe();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  /**
   * Reset params and search
   */
  onReset(): void {
    this.wheres._q = null;
    this.onSearch();
  }

  /**
   * Search with params
   *
   * @param wheres
   */
  onSearch(wheres = {}): void {
    const pagination = this._pagination.getValue();
    pagination._page = 1;
    this.wheres = this.beforeSearch(this.wheres);
    this.wheres = {
      ...this.wheres,
      ...wheres,
    };
    this._pagination.next(pagination);

    this.getList().subscribe();
  }

  beforeSearch(wheres = {}) {
    return wheres;
  }

  /**
   * Get products
   *
   *
   * @param page
   * @param size
   * @param sort
   * @param order
   * @param search
   */
  getList(): Observable<any> {
    if (this._cdr) {
      this._cdr.markForCheck();
    }
    this._entities.next([]);
    this._isLoading.next(true);

    const { _page, _limit, _sort } = this._pagination.getValue();
    const params: any = {
      ...this.wheres,
      _page,
      _limit,
      _sort,
    };
    if (this.wheres._sort) {
      params._sort = this.wheres._sort;
    }
    if (this.wheres['date-min']) {
      params['date-min'] = moment(this.wheres['date-min']).format('YYYY-MM-DD 00:00:00');
    }
    if (this.wheres['date-max']) {
      params['date-max'] = moment(this.wheres['date-max']).format('YYYY-MM-DD 23:59:59');
    }
    return this._resource
      .query(
        { observe: 'response' },
        _omitBy(
          params,
          (data: any) =>
            data === '' || data === null || typeof data === 'undefined' || (_isArray(data) && data.length === 0)
        ),
        params
      )
      .pipe(
        map(
          // Log the result or error
          (response: any, result) => {
            if (response?.meta?.pagination) {
              const pagination = response?.meta?.pagination;
              this._pagination.next({
                _page: pagination?.current_page,
                _limit: pagination?.per_page,
                _total: pagination?.total,
                _pages: pagination?.total_pages,
              });
              this.setQueryParams(params);
            }

            const list = this.onLoadData(response?.data);
            this._entities.next(list);
            this.load.emit(list);
            this._isLoading.next(false);
            if (!environment.production) {
              console.table(list);
            }
            if (response?.data.length > 0) {
              this._notFound.next(false);
            } else {
              this._notFound.next(true);
            }
            /* eslint-disable-next-line */
            if (this['_crd']) {
              this._cdr.detectChanges();
            }
            return of(response);
          }
        ),
        catchError(e => {
          this._isLoading.next(false);
          return of(e);
        })
      );
  }

  /**
   * Open dialo for save new entity
   *
   * @param state
   */
  openDialogNew(state = {}) {
    state = {
      ...state,
    };
    const initialState: ModalOptions = {
      initialState: state,
      class: 'modal-lg',
      ignoreBackdropClick: true,
      keyboard: false,
    };

    this.bsModalRef = this._modalService.show(this.dialogNew, initialState);
    this.onFocusForm();
    this.bsModalRef.onHidden.subscribe((result: any) => {
      const entity = this.bsModalRef?.content?.entity;
      if (entity?.id) {
        this._entities.next([entity].concat(this._entities.getValue()));
        this._notFound.next(false);
      }
    });
  }

  onLoadData(data: any) {
    return data;
  }

  /**
   * Open modal for edit entity
   *
   * @param entity entity for edit
   * @param state override properties component modal
   */
  openDialogEdit(entity: any = null, state = {}) {
    state = {
      ...state,
      entity: { ...entity },
    };
    const initialState: ModalOptions = {
      initialState: { ...state },
      class: 'modal-lg',
      ignoreBackdropClick: true,
      keyboard: false,
    };
    this.bsModalRef = this._modalService.show(this.dialogEdit, initialState);
    this.onFocusForm();
    this.bsModalRef.onHidden.subscribe((result: any) => {
      const entityU = { ...this.bsModalRef?.content?.entity };
      if (entityU?.id && entityU?.action === 'saved') {
        let entities = this._entities.getValue();
        entities = entities.map(row => {
          // @ts-ignore
          if (row.id === entityU?.id) {
            row = { ...entityU };
          }
          return row;
        });
        this._entities.next(entities);
        this._notFound.next(false);
      }
    });
  }

  /**
   * Open modal for show entity
   *
   * @param entity entity for show
   * @param state override properties component modal
   */
  openDialogDetail(entity: any = null, state = {}) {
    state = {
      ...state,
      entity: { ...entity },
    };
    const initialState: ModalOptions = {
      initialState: state,
      class: 'modal-lg',
      ignoreBackdropClick: true,
      keyboard: false,
    };
    this.bsModalRef = this._modalService.show(this.dialogDetail, initialState);
    // this.bsModalRef.onHidden.subscribe((result: any) => {
    //   const entityU = this.bsModalRef?.content?.entity;
    //   if (entityU?.id && entityU?.action === 'saved') {
    //     let entities = this._entities.getValue();
    //     entities = entities.map(row => {
    //       if (row.id === entityU?.id) {
    //         row = { ...entityU };
    //       }
    //       return row;
    //     });
    //     this._entities.next(entities);
    //     this._notFound.next(false);
    //   }
    // });
  }

  /**
   * Autofocus Form
   *
   * @param number_form
   * @param number_input
   * @param tag_name
   */
  onFocusForm(number_form: number = 1, number_input: number = 0, tag_name: string = 'input') {
    (document.forms[number_form]?.getElementsByTagName(tag_name)[number_input] as HTMLElement)?.focus();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  /**
   * Confirm for delete entity
   *
   * @param entity
   * @param e
   */
  onRemove(entity: any = null, e: any = null): any {
    Swal.fire({
      heightAuto: false,
      title: '¿Estás seguro?',
      text: 'Se eliminará este registro',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: 'gray',
      confirmButtonText: '¡Si, elimínalo!',
      cancelButtonText: 'Cancelar',
    }).then((result: any) => {
      if (result.value) {
        this._resource
          .delete({
            id: entity.id,
          })
          .subscribe(
            (response: any) => {
              entity.deleted_at = true;
              Swal.fire('¡Eliminado!', 'El registro ha sido eliminado.', 'success');
              if (this._cdr) {
                this._cdr.markForCheck();
              }
            },
            (error: any) => {
              if (error.status === 423) {
                this.toastService.error(error.body);
              }
            }
          );
        return true;
      }

      return false;
    });
  }

  /**
   * @param entity
   */
  onRestore(entity: any) {
    Swal.fire({
      heightAuto: false,
      title: '¿Estás seguro?',
      text: 'Se restaurará el registro',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, restauralo!',
    }).then((result: any) => {
      if (result.value) {
        this._resource
          .restore({
            id: entity.id,
          })
          .subscribe(
            (response: any) => {
              entity.deleted_at = null;
              Swal.fire('¡Restaurado!', 'El registro ha sido restaurado.', 'success');
              if (this._cdr) {
                this._cdr.markForCheck();
              }
            },
            (error: any) => {
              if (error.status === 423) {
                this.toastService.error(error.body);
              }
            }
          );
      }
      return result.value;
    });
  }

  /**
   * Event for change pagination and update an request new list
   *
   * @param $event
   */
  onPageChanged($event: any): void {
    const pagination = this._pagination.getValue();
    pagination._page = $event.page;
    this._pagination.next(pagination);

    this.getList().subscribe();
  }

  /**
   * Set params in query params for no lost navigacion un pagination on refresh page
   *
   * @param params
   */
  setQueryParams(params: Record<string, any>) {
    if (!this.preserveQueryParams) {
      return;
    }
    const pagination = this._pagination.getValue();
    const urlTree = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: {
        ...params,
        _page: pagination._page,
        _limit: pagination._limit,
      },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });

    this.router.navigateByUrl(urlTree);
  }

  sortData(sort: Sort) {
    this.wheres._sort = `${sort.direction === 'desc' ? '-' : ''}${sort.active}`;

    this.getList().subscribe();
  }

  /**
   * Select entity when de list is for select any row for set in form
   *
   * @param entity
   */
  onSelect(entity: any) {
    if (!environment.production) {
      console.debug(entity);
    }
    this._entity.next(entity);
    this._modalService.hide(99999);
  }

  /**
   * Close event for select modal
   *
   * @param entity
   */
  onCloseModal() {
    this._modalService.hide();
    // this._modalService.hide(99999);
  }

  onReport(entity: string, type: string = 'xlsx', title: string = 'default') {
    this._resource
      .report({
        _model: entity,
        _type: type,
        _title: title,
        ..._omitBy(
          this.wheres,
          (data: any) =>
            data === '' || data === null || typeof data === 'undefined' || (_isArray(data) && data.length === 0)
        ),
      })
      .subscribe(
        (response: any) => {
          if (response.size <= 6450) {
            return this.toastService.info({ message: 'No hay datos para exportar' });
          }
          const blob = new Blob([response]);
          const element = window.document.createElement('a');
          element.href = window.URL.createObjectURL(blob);
          element.download = `${title}.${type}`;
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        },
        (error: any) => {
          this.toastService.error({ message: 'Ha ocurrido un error, inténtelo mas tarde' });
        }
      );
  }

  getSelects(params): Observable<any> {
    this._store.dispatch(
      updateAppState({
        app: { isLoading: true },
      })
    );
    return this._resource.selects(params).pipe(
      tap((response: any) => {
        this.selects = {
          ...this.selects,
          ...response.data,
        };
        this._cdr.detectChanges();
        this._store.dispatch(
          updateAppState({
            app: { isLoading: false },
          })
        );
      }),
      catchError((error: any) => {
        this._store.dispatch(
          updateAppState({
            app: { isLoading: false },
          })
        );
        return of([]);
      })
    );
  }

  openDialogCatalog(dialogComponent: any, callback: any = null, state = {}) {
    state = {
      isSelectable: true,
      isModal: true,
      ...state,
    };
    const initialState: ModalOptions = {
      id: 99999,
      initialState: state,
      class: 'modal-xl',
      ignoreBackdropClick: true,
      keyboard: false,
    };

    const bsModalRef = this._modalService.show(dialogComponent, initialState);
    bsModalRef?.onHidden.subscribe((result: any) => {
      const entity = bsModalRef?.content?._entity.getValue();
      if (callback) {
        callback(entity);
      }
    });
  }
}
