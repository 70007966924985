import { createReducer, on } from '@ngrx/store';
import { clearSession, setSession } from '@shared/state/actions/session.actions';

export const initialState = {
  user: {},
};

const _sessionReducer = createReducer(
  initialState,
  on(setSession, (state, { session }) => ({ ...state, ...session })),
  on(clearSession, state => initialState)
);

export const sessionReducer = (state: any, action: any) => _sessionReducer(state, action);
