import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ISelectType } from '@shared/global.interface';
import { updateAppState } from '@shared/state/actions/app.actions';
import { selectAppIsLoading } from '@shared/state/selectors/app.selector';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject, catchError, Observable, of, Subject, tap } from 'rxjs';
import { OpenNewServiceService } from './open-new-crud.service';
import { ToastService } from './toast.service';

@Directive()
export class BaseCrudService<T = Record<any, any>> extends OpenNewServiceService implements OnInit, OnDestroy {
  @Output() save = new EventEmitter<T>();
  @Output() update = new EventEmitter<T>();
  @Input() selects: ISelectType = {} as ISelectType;
  edit = false;
  detail = false;
  edited = false;
  @Input() entity: T = {} as T;
  @Input() modalId = null;
  protected _isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected _unsubscribeAll: Subject<any> = new Subject<any>();
  protected _toastService: ToastService;
  protected _resource: any;
  protected _modalService: any;
  public bsModalRef: BsModalRef;
  protected _cdr: ChangeDetectorRef;
  protected _formBuilder: UntypedFormBuilder;
  protected _store: Store;
  public formGroup: UntypedFormGroup | any;
  @Input() saveNoAction = false;
  appIsLoading$: Observable<boolean>;

  constructor(@Inject(Injector) protected injector: Injector) {
    super(injector);
    this._toastService = injector.get(ToastService);
    this._cdr = injector.get(ChangeDetectorRef);
    this.bsModalRef = injector.get(BsModalRef);
    this._modalService = injector.get(BsModalService);
    this._formBuilder = injector.get(UntypedFormBuilder);
    this._store = injector.get(Store);
    this.appIsLoading$ = this._store.select(selectAppIsLoading);
  }

  ngOnInit(): void {
    return;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  onUpdate(): void {
    this.formGroup.submitted = true;
    if (!this.formGroup.valid) {
      return;
    }
    this._isLoading.next(true);
    this.entity = this.beforeSave(this.entity);
    this.entity = this.cleanParams(this.entity);
    this._resource.update(this.entity).subscribe(
      ({ data }: any) => {
        // @ts-ignore
        this.entity = { ...this.entity, ...data, _with: this.entity?._with, action: 'saved' };
        this._isLoading.next(false);
        this.edited = true;
        this._cdr.detectChanges();
        if (!this.saveNoAction) {
          this.actionUpdate();
        }
        this.update.emit(this.entity);
      },
      (error: any) => {
        this._isLoading.next(false);
        this._toastService.error({
          message: error.body,
        });
      }
    );
  }

  beforeSave(entity: any) {
    return entity;
  }

  onSave(): void {
    this.formGroup.submitted = true;
    if (!this.formGroup.valid) {
      return;
    }
    this._isLoading.next(true);
    this.entity = this.beforeSave(this.entity);
    this.entity = this.cleanParams(this.entity);

    this._resource.store(this.entity).subscribe(
      ({ data }: any) => {
        // @ts-ignore
        this.entity = { ...this.entity, ...data, _with: this.entity?._with, action: 'saved' };
        this._isLoading.next(false);
        this._cdr.detectChanges();
        if (!this.saveNoAction) {
          this.actionSave();
        }
        this.save.emit(this.entity);
      },
      (error: any) => {
        this._isLoading.next(false);
        this._toastService.error({
          message: error.body,
        });
      }
    );
  }
  actionUpdate(): void {
    this._toastService.success({
      message: 'Se ha actualizado la informacion correctamente',
    });
    setTimeout(() => {
      if (this.modalId) {
        this.bsModalRef?.hide();
        //this.bsModalRef?.hide(this.modalId);
      } else {
        this.bsModalRef?.hide();
      }
    });
  }

  actionSave(): void {
    this._toastService.success({
      message: 'Se ha guardado correctamente',
    });
    setTimeout(() => {
      if (this.modalId) {
        this.bsModalRef?.hide();
        //this.bsModalRef?.hide(this.modalId);
      } else {
        this.bsModalRef?.hide();
      }
    });
  }

  cleanParams(params: Record<string, any>): any {
    return params;
  }

  get isLoading$(): Observable<boolean> {
    return this._isLoading.asObservable();
  }

  openDialogCatalog(dialogComponent: any, callback: any = null, state = {}) {
    state = {
      ...state,
      isSelectable: true,
      isModal: true,
    };
    const initialState: ModalOptions = {
      id: 99999,
      initialState: state,
      class: 'modal-lg',
      ignoreBackdropClick: true,
      keyboard: false,
    };
    const bsModalRef = this._modalService.show(dialogComponent, initialState);
    bsModalRef?.onHidden.subscribe((result: any) => {
      const entity = bsModalRef?.content?._entity.getValue();
      if (callback) {
        callback(entity);
      }
    });
  }

  onResetControl() {
    // @ts-ignore
    this.entity.controlId = null;
    // @ts-ignore
    this.entity.controlName = null;
    this._cdr.detectChanges();
  }

  getSelects(params): Observable<any> {
    this._store.dispatch(
      updateAppState({
        app: { isLoading: true },
      })
    );
    return this._resource.selects(params).pipe(
      tap((response: any) => {
        this.selects = {
          ...this.selects,
          ...response.data,
        };
        this._cdr.detectChanges();
        this._store.dispatch(
          updateAppState({
            app: { isLoading: false },
          })
        );
      }),
      catchError((error: any) => {
        this._store.dispatch(
          updateAppState({
            app: { isLoading: false },
          })
        );
        return of([]);
      })
    );
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
