import { Injectable, NgZone } from '@angular/core';
import { AuthResource } from '@modules/auth/resources/auth.resource';
import { Store } from '@ngrx/store';
import { setSession } from '@shared/state/actions/session.actions';

@Injectable({ providedIn: 'root' })
export class GlobalService {
  abilities = null;
  roles = null;
  user = null;
  constructor(private _authResource: AuthResource, private _store: Store, private _zone: NgZone) {}
  init(): Promise<any> {
    if (!window.localStorage.getItem('token')) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      this._authResource.me().subscribe(
        (response: any) => {
          this.abilities = response?.data?.abilities;
          this.roles = response?.data?.role;
          this.user = response?.data?.user;
          this._store.dispatch(setSession({ session: response?.data }));
          this._zone.run(() => {});
          resolve(response);
        },
        error => {
          window.localStorage.clear();
          window.sessionStorage.clear();
          resolve(error);
        }
      );
    });
  }

  hasRol(rol) {
    return this.roles.includes(rol);
  }
}

export const configDropDown = {
  displayKey: 'name',
  // displayFn: (item: any) => item?.name,
  search: true,
  limitTo: 8,
  searchOnKey: 'name',
  moreText: 'más',
  searchPlaceholder: 'Buscar',
  noResultsFound: 'Sin resultados!',
  height: 'auto',
  placeholder: 'Seleccione una opción',
};
