import { Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { getMessaging, getToken, isSupported, onMessage } from '@angular/fire/messaging';
import { environment } from '@environments/environment';
import { UserResource } from '@modules/user/resources/user.resource';
import { Store } from '@ngrx/store';
import { updateAppState } from '@shared/state/actions/app.actions';
import { AppState } from '@shared/state/interfaces/app-state.interface';
import { SessionState } from '@shared/state/interfaces/session-state.interface';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class MessagingService {
  token;
  constructor(
    private _toastService: ToastService,
    private app: FirebaseApp,
    private _store: Store<{ session: SessionState; app: AppState }>,
    private userResource: UserResource
  ) {}
  init(): void {
    try {
      isSupported().then(result => {
        if (result) {
          this.register();
        }
      });
    } catch (error) {
      console.debug(error);
    }
  }

  setTokenSender() {
    this.userResource
      .setTokenSender({
        token_sender: this.token,
      })
      .subscribe();
  }

  register(): void {
    const messaging = getMessaging(this.app);

    getToken(messaging, {
      vapidKey: environment.firebase.vapidKey,
    })
      .then(currentToken => {
        if (currentToken) {
          console.debug(currentToken);
          this.token = currentToken;
          this.setTokenSender();
          onMessage(messaging, payload => {
            this._store.dispatch(
              updateAppState({
                app: { notificationNew: true },
              })
            );
            console.debug(payload);
            this._toastService.info({
              title: payload?.notification?.title,
              message: payload?.notification?.body,
            });
            /* this._notificationService.createNotification({
              ...payload?.notification,
              ...payload?.data,
            }); */
            // update indicator new notifications
          });
        } else {
          // Show permission request UI
          console.debug('No registration token available. Request permission to generate one.');
        }
      })
      .catch(err => {
        console.debug('An error occurred while retrieving token. ', err);
      });
  }
}
