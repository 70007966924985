import { Injectable } from '@angular/core';
import { IResourceMethodObservable, ResourceAction, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core';
import { BaseResource } from 'src/app/shared/resources/base.resources';

@Injectable()
@ResourceParams({
  pathPrefix: 'auth',
})
export class AuthResource extends BaseResource {
  @ResourceAction({
    path: '/login',
    method: ResourceRequestMethod.Post,
  })
  login: IResourceMethodObservable<object, object> | any;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/signup',
    auth: false,
  })
  signup: IResourceMethodObservable<object, object>;

  @ResourceAction({
    pathPrefix: '/auth',
    method: ResourceRequestMethod.Get,
    path: '/me',
  })
  me: IResourceMethodObservable<object, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/user/verify/{!code}',
    auth: false,
  })
  activate: IResourceMethodObservable<object, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/password/reset',
    auth: false,
  })
  reset: IResourceMethodObservable<object, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/token/verify',
    auth: false,
  })
  verify: IResourceMethodObservable<object, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/password/email',
    auth: false,
  })
  recovery: IResourceMethodObservable<object, object>;
}
