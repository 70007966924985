export const environment = {
  firebase: {
    projectId: 'siarke',
    appId: '1:562736870778:web:5c534f7ef5adebd19c92c3',
    storageBucket: 'siarke.appspot.com',
    apiKey: 'AIzaSyA052loux2lqv8SLWdfErIWWpEnGljA9Uk',
    authDomain: 'siarke.firebaseapp.com',
    messagingSenderId: '562736870778',
    measurementId: 'G-FE783QVKRZ',
    vapidKey: 'BDX7b2L1onoouuKqy93qt5EUezKIRd5mEsgpTaIpq3Q8mlqslXN0Y2aTFohniPagOq2MTmVdf89-aElMLLdQo9c',
  },
  production: false,
  urlApi: 'https://staging-api.arke.com.mx/',
  urlAssets: 'https://staging-api.arke.com.mx/',
};
