import { NgModule } from '@angular/core';
import { AclPipe } from './acl.pipe';
import { AclrPipe } from '@shared/pipes/aclr.pipe';
import { AgePipe } from '@shared/pipes/age.pipe';

@NgModule({
  declarations: [AclPipe, AclrPipe, AgePipe],
  exports: [AclPipe, AclrPipe, AgePipe],
})
export class PipesModule {}
